.app-store-link {
  width: 300px;
  height: 80px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    filter: brightness(0.9);
  }
}
