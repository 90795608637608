input.lib-text-input {
  height: 48px;
  border-radius: 8px;
  width: 100%;
  padding: 14px 32px 14px 20px;
  border: 1px solid var(--color-greyscale-3);
  font-family: inherit;
  &:disabled {
    background-color: var(--color-greyscale-2);
  }
}
.text-input-parent {
  margin: 1rem 0;
}
.text-input-parent label {
  font-size: 0.9rem;
  display: block;
  color: var(--color-grayscale-4);
  margin-bottom: 0.5rem;
}
