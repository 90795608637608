.footer {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  padding: var(--space-lg) var(--space-sm);
  display: grid;
  @media (min-width: 800px) {
    display: grid;
    padding: var(--space-lg) var(--space-page);
    grid-template-columns: 1fr auto auto auto;
    gap: var(--space-lg);
  }

  .footer--address {
    display: flex;
    flex-direction: column;
    gap: 5px;
    img {
      width: 100px;
      margin-bottom: var(--space-md);
    }
    .address-line {
      font-size: 0.8rem;
      opacity: 0.4;
    }
  }

  .footer--nav-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-lg);
    padding: var(--space-md) 0;
    @media (min-width: 800px) {
      padding: 0;
    }
    .footer--nav-column {
      display: flex;
      flex-direction: column;
      gap: var(--space-xs);
      min-width: 80px;
      h5 {
        margin-bottom: var(--space-sm);
      }
      p {
        font-size: 0.8rem;
      }
      a {
        color: black;
        text-decoration: none;
        font-size: 0.8rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
