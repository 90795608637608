.email-page {
  padding: 30px var(--space-page) 0 var(--space-page);
  display: flex;
  align-items: center;
  gap: var(--space-md);
  flex-direction: column;
  text-align: center;
  .content-wrapper {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .email-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--space-sm);
      width: 100%;
    }
    &.initial-card {
      justify-content: space-between;
    }
  }
  .card-email {
    max-width: 592px;
    margin: 0 auto;
  }
}
