.nota-reimagined-page {
    .hero-section {
        position: relative;
        padding: 1rem 1rem 10rem;
        background-color: #222222;
        .hero-white-triangle {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            background: white;
            clip-path: polygon(200% 0%, 100% 100%, 0% 100%);
            z-index: 1;
        }
        .logo-container {
            width: 12rem;
            margin-bottom: 3rem;
        }

        .hero-content {
            * {
                color: white;
            }
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            position: relative;
            z-index: 2;

            h1 {
                font-size: 1.9rem;
                margin-bottom: 0.5rem;
                font-weight: bold;
                p {
                    font-size: 1.1rem;
                    margin-bottom: 0.5rem;
                }
            }
            p {
                font-weight: bold;
            }

            .mobile-hero-image {
                width: 143px;
                img {
                    width: 100%;
                }
            }

            .button-wrap {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-top: 0.5rem;
                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    color:#074F6F;
                    font-weight: bold;
                    background: #E5D42E;
                    border: none;
                    border-radius: 5px;
                    padding: 9px 10px;
                    cursor: pointer;
                    transition: background-color ease 0.4s;
                    &:hover {
                        background: rgb(255, 235, 52);
                    }

                    &.apple img {
                        position: relative;
                        top: -2px;
                    }
                }
            }

            .desktop-hero-image {
                display: none;
            }
        }
    }

    .preview-section {
        position: relative;
        margin: -8rem 1rem 5rem;
        padding: 1.2rem;
        box-shadow: 0px 1px 4px 0px #0000001C;
        background: #fff;
        z-index: 3;
        border-radius: 10px;

        .preview-header {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 3px solid #f6f6f6;
            h2, p {
                color:#074F6F;
            }
            p {
                font-weight: bold;
            }
            h2 {
                font-size: 1.9rem;
                margin-bottom: 1rem;
            }
            form {
                display: flex;
                input, button {
                    flex: 1;
                    border: none;
                    padding: 10px;
                    background: transparent;
                    border: solid #074F6F 1px;
                }
                input {
                    border-right-color: transparent;
                    border-radius: 18px 0 0 18px;
                    flex-grow: 1.5;
                }
                button[type="submit"] {
                    border-radius: 0 18px 18px 0;
                    border-left-color: transparent;
                    background: #E5D42E;
                    color: #074F6F;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    &:not([disabled]) {
                        cursor: pointer;
                        transition: background-color ease 0.4s;
                    }
                    &:not([disabled]):hover {
                        background: rgb(255, 235, 52);
                    }
                    .submit-button {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        svg {
                            fill: #074F6F;
                        }
                    }
                    .lds-ellipsis {
                        transform: scale(0.5);
                        position: relative;
                        bottom: -1px;
                    }
                }
            }
        }

        .get-excited-for {
            font-size: 1.1rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        .feature-grid {
            > div {
                display: flex;
                gap: 1rem;
                margin-bottom: 1.5rem;
                &:nth-child(odd) {
                    flex-direction: row-reverse;
                }
            }
            .feature-image {
                display: flex;
                justify-content: center;
                width: 50%;
                text-align: center;
                // padding: 1rem 0 0;
                border-radius: 5px;
                // &.dark {
                //     background-color: #074F6F;
                // }
                // &.light {
                //     background-color: #93C7D4;
                // }
                img {
                    position: relative;
                    width: 140px;
                    // height: 167px;
                }
            }
            .feature-text {
                width: 50%;
                .title {
                    font-weight: 600;
                    font-size: calc(1rem - 2px);
                }
                .description {
                    font-size: 1rem;
                }
            }
        }
    }

    .floater {
        position: absolute;
        border-radius: 10px;
        z-index: 10;

        &.red {
            background: #E06253;
        }
        &.blue {
            background: #074F6F;
        }
        &.yellow {
            background: #E5D42E;
        }

        &.mobile {
            width: 82px;
            height: 46px;
        }

        &.desktop {
            display: none;
        }
        &.small {
            width: 52px;
            height: 29px;
            border-radius: 7px;
        }
        &.medium {
            width: 63px;
            height: 35px;
            border-radius: 7px;
        }
        &.large {
            width: 115px;
            height: 64px;
            border-radius: 10px;
        }
    }
    #mobileFloater1 {
        top: -23px;
        left: 60%;
    }
    #mobileFloater2 {
        top: -15px;
        left: -67px;
    }
    #mobileFloater3 {
        top: 44%;
        right: -1rem;
        width: 2rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    #mobileFloater4 {
        bottom: -23px;
    }
    #floater1 {
        top: -106px;
        left: 16%;
    }
    #floater2 {
        top: -64px;
        left: 23%;
    }
    #floater3 {
        top: -40px;
        left: 32%;
    }
    #floater4 {
        top: -30px;
        left: 66%;
    }
    #floater5 {
        top: 115px;
        left: 26%;
    }
    #floater6 {
        top: 140px;
        left: 70%;
    }
    #floater7 {
        top: 38%;
        left: -86px;
    }
    #floater8 {
        top: 65%;
        right: -32px;
    }
    #floater9 {
        bottom: -17.5px;
        left: 20%;
    }

    @keyframes floatVertical {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-5px);
        }
        100% {
          transform: translateY(0px);
        }
    }
    @keyframes floatHorizontal {
        0% {
          transform: translateX(0px);
        }
        50% {
          transform: translateX(-5px);
        }
        100% {
          transform: translateX(0px);
        }
    }
      
    .floatingVertical {
        animation-name: floatVertical;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
    .floatingHorizontal {
        animation-name: floatHorizontal;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
      
}

@media screen and (min-width: 1024px) {
    .nota-reimagined-page {
        .hero-section {
            padding: 2rem 6rem 6rem;
            .hero-white-triangle {
                clip-path: polygon(500% 0%, 100% 100%, 0% 100%);
            }

            .container {
                max-width: 1200px;
                margin: 0 auto;
            }

            .logo-container {
                margin-bottom: 2rem;
            }

            .hero-content {
                align-items: center;
                .hero-text {
                    position: relative;
                    top: -4rem;
                }
                h1 {
                    margin-bottom: 1.5rem;
                }
                .button-wrap {
                    flex-direction: row;
                    button {
                        flex: 1;
                    }
                }
                .mobile-hero-image {
                    display: none;
                }
                .desktop-hero-image {
                    display: block;
                    position: relative;
                    .bg-polygon {
                        position: absolute;
                        left: -10%;
                        z-index: 1;
                    }
                    .phones {
                        position: relative;
                        z-index: 2;
                        display: flex;
                        gap: 2rem;
                    }
                }
            }
        }

        .preview-section {
            max-width: 1300px;
            margin: -8rem auto 5rem;
            position: relative;
            border-radius: 20px 0 20px 20px;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                top: -64px;
                left: 0;
                background: #FFF;
                clip-path: polygon(2% 18%, 103% -1%, 100% 20%, 1% 20%);
                border-radius: 20px;
                z-index: 10;
            }

            .preview-header {
                text-align: center;
                border-bottom: none;
                margin-bottom: 3rem;

                p {
                    font-size: 1.12rem;
                    margin-bottom: 1.5rem;
                }
                h2 {
                    font-size: 3.1rem;
                    margin-bottom: 2rem
                }
                form {
                    width: 350px;
                    margin: 0 auto;

                    input {
                        flex-grow: 2;
                    }
                }
            }
            .get-excited-for {
                display: none;
            }

            .feature-grid {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 1rem;
                > div {
                    width: 30%;
                }
                > div:nth-child(odd) {
                    flex-direction: initial;
                }
                .feature-image {
                    width: 40%;
                }
            }
        }

        .floater {
            position: absolute;
    
            &.desktop {
                display: block;
            }
            &.mobile {
                display: none;
            }
        }
    }
}