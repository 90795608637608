textarea.lib-text-area-input {
  border-radius: 8px;
  min-height: 200px;
  width: 100%;
  padding: 14px 32px 14px 20px;
  margin: 2em;
  border: 1px solid var(--color-greyscale-3);
  resize: none;
  &:not(:placeholder-shown) {
    border-color: #5ABF7E;
  }
  &:disabled {
    background-color: var(--color-greyscale-2);
  }
}
