@import "./theme";

#app {
  height: 100vh;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
// Small devices
@mixin sm {
  @media (min-width: 800px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: 1000px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: 1200px) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: 1400px) {
    @content;
  }
}
