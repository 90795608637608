/*
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;

  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &:not(:first-of-type){
      &::before {
        position: absolute;
        content: "";
        border-bottom: 2px dashed var(--color-primary-2);
        width: 25%;
        top: 50%;
        left: 0;
        z-index: 2;
      }
    }
    &:not(:last-of-type){
      &::after {
        position: absolute;
        content: "";
        border-bottom: 2px dashed var(--color-primary-2);
        width: 25%;
        top: 50%;
        right: 0;
        z-index: 2;
      }
    }

    .step-counter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--color-primary-1);
      margin-bottom: 6px;
      font-weight: bold;
      border: 4px solid var(--color-primary-4);
    }

    &.active {
      color: var(--color-primary-4);
      .step-counter {
        background-color:  var(--color-primary-4);
        color: var(--color-greyscale-1);
        border: 4px solid var(--color-primary-4);
      }
      &::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid var(--color-primary-4);
        width: 25%;
        top: 50%;
        right: 0;
        z-index: 2;
      }
      &:not(.no-before){
        &::before {
          position: absolute;
          content: "";
          border-bottom: 2px solid var(--color-primary-4);
          width: 25%;
          top: 50%;
          left: 0;
          z-index: 2;
        }
      }


    }

    &.completed {
      color: white;
      .step-counter {
        background-color: var(--color-primary-4);
      }
      &::after {
        border-bottom: 2px solid var(--color-primary-4);
      }
      &::before {
        border-bottom: 2px solid var(--color-primary-4);
      }
    }

    &:first-child::before {
      content: none;
    }

    &:last-child::after {
      content: none;
    }
  }
}
*/
.stepper-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  --indicator-size: 2.5rem;
  gap: 1rem;
  @media only screen and (min-width: 50rem) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
  }
}
.stepper-logo {
  height: var(--indicator-size);
  width: auto;
  display: block;
}
.step-indicators-wrapper {
  position: relative;
  @media only screen and (min-width: 35rem) {
    flex-grow: 1;
  }
}
.connector {
  position: absolute;
  width: 100%;
  z-index: 0;
  top: calc(var(--indicator-size) / 2);
  border-top: 2px dashed var(--color-primary-2);
}
.step-indicators {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  @media only screen and (min-width: 35rem) {
    gap: 2vw;
  }
}
.step-indicator {
  position: relative;
  background: var(--color-primary-1);
  &:where([data-active="true"], [data-complete="true"]) .step-number {
    color: var(--color-primary-1);
    background: var(--color-primary-4);
  }
  &[data-active="true"] .step-title {
    display: block;
  }
  &[data-complete="true"]:after {
    content: "";
    position: absolute;
    top: calc(var(--indicator-size) / 2);
    left: var(--indicator-size);
    width: 16vw;
    z-index: -1;
    border-top: 2px solid var(--color-primary-4);
  }
  @media only screen and (min-width: 35rem) {
    display: flex;
    align-items: center;
    padding: 0 clamp(0.25rem, 1vw, 0.5rem);
  }
}
.step-number {
  height: var(--indicator-size);
  width: var(--indicator-size);
  background: var(--color-primary-1);
  border-radius: 50%;
  border: 0.25rem solid var(--color-primary-4);
  line-height: 2;
  font-weight: 800;
  text-align: center;
}
.step-title {
  display: none;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0.35rem -1ch;

  @media only screen and (min-width: 70rem) {
    display: block;
  }
  @media only screen and (min-width: 35rem) {
    margin: 0 0 0 0.5rem;
  }
}
