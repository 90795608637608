.card-container {
  z-index: 1;

  .card {
    width: 100%;
    max-width: 55ch;

    &.results-card {
      max-width: 100%;
    }
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    background-color: white;
    border-top: 4px solid var(--color-accent-3);
    &::before{
      min-width: 100%;
      content:'hello';
      position: absolute;
      left: -1px;
      top: -4px;
      z-index: -1;
      background-color: var(--color-accent-3);
      border-radius: 8px;
    }
    @media only screen and (min-width: 35rem) {
      padding: var(--space-md);
    }
    .bg-circle {
      position: absolute;
      z-index: -1;
      &.large-circle {
        bottom: -41px;
        left: -55px;
      }
      &.medium-circle {
        top: 39px;
        right: -48px;
      }
      &.small-circle {
        top: 20px;
        right: -66px;
      }
    }
    .card-title-section {
      text-align: center;
      margin-bottom: var(--space-md);
      h1 {
        margin: 1rem 0;
      }
      p {
        max-width: 60ch;
        margin: 0 auto;
      }
    }
    :last-child.button {
      margin-top: var(--space-md);
    }
  }
}
