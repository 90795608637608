.video-player {
  width: 100%;
  display: flex;
  justify-content: center;

  video {
    width: 250px;
    height: 445px;
  }
}
