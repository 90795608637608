@font-face {
  font-family: CustomNoirProBold;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-Bold.otf");
}
@font-face {
  font-family: CustomNoirProBoldItalic;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-BoldItalic.otf");
}
@font-face {
  font-family: CustomNoirProHeavy;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-Heavy.otf");
}
@font-face {
  font-family: CustomNoirProHeavyItalic;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-HeavyItalic.otf");
}
@font-face {
  font-family: CustomNoirProItalic;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-Italic.otf");
}
@font-face {
  font-family: CustomNoirProLight;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-Light.otf");
}
@font-face {
  font-family: CustomNoirProLightItalic;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-LightItalic.otf");
}
@font-face {
  font-family: CustomNoirProMedium;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-Medium.otf");
}
@font-face {
  font-family: CustomNoirProMediumItalic;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-MediumItalic.otf");
}
@font-face {
  font-family: CustomNoirProSemiBold;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-SemiBold.otf");
}
@font-face {
  font-family: CustomNoirProSemiBoldItalic;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-SemiBoldItalic.otf");
}
@font-face {
  font-family: CustomNoirProRegular;
  src: url("../../../assets/fonts/Noir Pro/NoirPro-Regular.otf");
}
