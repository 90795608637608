$bar-height: 0.75rem;

.progress-bar {
  width: 100%;
  background-color: white;
  border-radius: calc(#{$bar-height} + 2px);
  border: 1px solid var(--color-light-outline);

  div {
    height: $bar-height;
    border-radius: 10px;
    min-width: 10px;

    transition: 0.2s all ease;
    &.progress-bar {
      background-color: var(--color-primary);
    }
  }
}
