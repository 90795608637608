:root {
  --color-light-outline: hsl(0, 0%, 84%);

  --color-primary-dark: #085165;
  --color-primary-darker: #012533;
  --color-primaryd-darkest: #151516;

  --color-primary: #277b9d;
  --color-primary-light: #93c7d4;
  --color-primary-lightest: #e6f4ff;

  --color-primary-1: #e6f4ff;
  --color-primary-2: #93c7d4;
  --color-primary-3: #277b9d;
  --color-primary-4: #08516f;
  --color-primary-5: #012533;
  --color-primary-6: #151516;

  --color-accent-1: #daf4f6;
  --color-accent-2: #aee8eb;
  --color-accent-3: #82dbe0;
  --color-accent-4: #e5d52d;
  --color-accent-5: #007b83;
  --color-accent-6: #004146;

  --color-error-1: #ffd7d6;
  --color-error-2: #ffa9a6;
  --color-error-3: #ff7a75;
  --color-error-4: #ff4b45;
  --color-error-5: #be3833;
  --color-error-6: #7d2522;

  --color-success-1: #dbf1e3;
  --color-success-2: #b0e0c1;
  --color-success-3: #85d0a0;
  --color-success-4: #5abf7e;
  --color-success-5: #438e5e;
  --color-success-6: #2c5e3e;

  --color-warning-1: #fcf9e3;
  --color-warning-2: #f1e891;
  --color-warning-3: #ebdd60;
  --color-warning-4: #e5d32e;
  --color-warning-5: #ac9e23;
  --color-warning-6: #736a17;

  --color-greyscale-1: #f6f6f6;
  --color-greyscale-2: #c9c9c9;
  --color-greyscale-3: #9c9c9c;
  --color-greyscale-4: #6f6f70;
  --color-greyscale-5: #424243;
  --color-greyscale-6: #151516;
}
