.circle-wrapper {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background-color: white;
    height: 50%;
    width: 100%;
    top: 50%;
  }
  .circle-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
  .soft-circle {
    height: 150px;
    width: 150px;
    position: relative;
    border: solid #000 1px;
    border-radius: 50%;
    transform: rotate(180deg);

    .inside {
      position: absolute;
      z-index: 6;
      top: 50%;
      left: 50%;
      height: 110px;
      width: 110px;
      margin: -55px 0 0 -55px;
      background: white;
      border-radius: 100%;
    }

    .shade {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #eee;
      border-radius: 100%;
      clip: rect(0px, 150px, 150px, 74px);

      .progressing {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        clip: rect(0px, 74px, 150px, 0px);
      }

      &.left {
        .progressing {
          z-index: 1;
        }
      }

      &.right {
        transform: rotate(180deg);
        z-index: 3;
      }
    }
  }
}
