select {
  height: 48px;
  border-radius: 24px;
  width: 100%;
  padding: 14px 24px;
  border: 1px solid var(--color-greyscale-1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:disabled {
    background-color: var(--color-greyscale-2);
  }
}
