.lds-spinner {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  div {
    transform-origin: 20px 20px;
    animation: lds-spinner 4.8s linear infinite;
    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -4.4s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -4s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -3.6s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -3.2s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -2.8s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -2.4s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -2s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -1.6s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -1.2s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.8s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.4s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 19px;
      width: 2px;
      height: 8px;
      border-radius: 20%;
      background: var(--color-primary-4);
    }
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
