.password-reset-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-lg);
  .error-text {
    color: var(--color-error-4);
  }
}
