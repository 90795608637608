.page {
  position: relative;
  overflow: hidden;
}
.background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -2;

  background-image: url("../../../assets/svgs/background.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: var(--color-primary-1);
}

.assessment-step-page {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-md);
  @media only screen and (min-width: 35rem) {
    padding: var(--space-md);
  }
  .text-input-wrapper {
    text-align: left;
    p,
    .disclaimer {
      color: var(--color-greyscale-3);
      .disclaimer-links {
        color: var(--color-accent-5);
        &:visited {
          color: var(--color-accent-5);
        }
        &:hover {
          color: var(--color-primary-5);
        }
      }
    }
    p,
    .name-input {
      margin: 10px 0;
      position: relative;
    }

    .error-text {
      position: absolute;
      top: 90%;
      left: 0;
      color: var(--color-error-4);
    }
  }
}

.interstitial {
  margin: auto;
  max-width: 60ch;
  text-align: center;
  .lds-spinner {
    margin-top: var(--space-md);
  }
}
.result-wrapper {
  max-width: 70rem;
  margin: 0 auto;

  @media only screen and (min-width: 50rem) {
    display: flex;
    gap: 1rem;
  }
  > div {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 2rem 0;
    @media only screen and (min-width: 50rem) {
      flex-direction: column;
      text-align: center;
    }
    .progress-wrapper {
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}
.password-validation-box {
  display: flex;
  flex-direction: column;

  div {
    font-size: 0.8em;
    padding: 5px 0;
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 8px;
    align-items: center;
  }
}

/*
.container {
	width: 100%;
	background-color: var(--color-accent-1);
	.page {
		position: relative;
		width: 100%;
		&:not(.download-page) {
			min-height: 100vh;
		}
		background-image: url("../../../assets/svgs/background.svg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		background-color: var(--color-primary-1);
		.name-title-section {
			* {
				margin-bottom: 20px;
			}
		}
	}

	.assessment-step-page {
		position: relative;
		padding: var(--space-md);
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.animation-fade {
			animation-name: fade-in;
			animation-timing-function: ease;
			animation-fill-mode: forwards;

			&.leaving {
				animation-name: fade-out;
			}
		}

		.progress-bar-logo {
			width: 300px;

		}

		.controls {
			width: 100%;
			display: flex;
			justify-content: space-evenly;

			@media screen and (min-width: 1200px) {
				gap: var(--space-md);
				justify-content: center;
			}
		}

		.back-button {
			position: absolute;
			top: var(--space-md);
			left: var(--space-md);
			z-index: 5;
			cursor: pointer;
			&:hover {
				filter: brightness(0.9);
			}
		}

		.continue-button {
			justify-self: center;
			width: 525px;
			height: 75px;
			border-radius: 10px;
			font-size: 25px;

			&:disabled {
				background-color: #d8d8d8;
			}
		}

		.assessment-step {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: var(--space-xs);

			&.name {
				width: 75%;
				display: grid;
				grid-template-rows: auto auto 1fr;
				gap: var(--space-sm);
				align-items: center;
				justify-items: center;
				text-align: center;
				position: relative;
				p,
				.disclaimer {
					color: var(--color-greyscale-3);
					.disclaimer-links {
						color: var(--color-accent-5);
						&:visited {
							color: var(--color-accent-5);
						}
						&:hover {
							color: var(--color-primary-5);
						}
					}
				}
				.name-card {
					width: 592px;
				}
				.text-input-wrapper {
					text-align: left;

					p,
					.name-input {
						margin: 10px 0;
						position: relative;
					}

					.error-text {
						position: absolute;
						top: 90%;
						left: 0;
						color: var(--color-error-4);
					}
				}
			}
			&.results {
				align-items: center;
				width: 800px;

				.content {
					text-align: center;
					font-size: 25px;
				}
				.results-divider {
					height: 4px;
					width: 100%;
					background-color: var(--color-primary-4);
				}
				.result-wrapper {
					margin: 20px 0;
					flex: 1;
					width: 100%;
					max-width: 150px;
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					font-size: 25px;
				}

				p .text-action {
					text-decoration: underline;
					cursor: pointer;
					transition: 0.2s;

					&:hover {
						opacity: 0.8;
					}
				}
			}

			&.interstitial {
				width: 600px;
				height: 100%;
				padding: 0 var(--space-lg) var(--space-lg);
				align-items: center;
				justify-content: center;
				gap: var(--space-lg);

				.content {
					text-align: center;
					font-size: 40px;
				}

				.interstitial-progress-bar {
					width: 400px;
					height: 5px;
					margin-top: var(--space-md);
					border-radius: 15px;
					overflow: hidden;
					background-color: var(--color-primary-lightest);

					.progress {
						height: 100%;
						min-width: 5%;
						background-color: var(--color-primary);
						animation: progress 2s linear forwards;
					}
				}
			}

			&.media {
				width: 100%;
				display: grid;
				grid-template-rows: auto auto 1fr;
				gap: var(--space-sm);
				justify-content: center;
				text-align: center;

				.content {
					font-size: 25px;
				}

				.media-wrapper {
					align-self: center;
					justify-self: center;
				}
			}

			&.range {
				display: grid;
				grid-template-rows: auto auto 1fr auto;
				text-align: center;
				width: 100%;

				.range-wrapper {
					align-self: center;
					margin-bottom: var(--space-md);
					width: 100%;
					.slider-container {
						width: 100%;
					}
				}
			}
		}
	}
}

*/
.bold {
  font-weight: bold;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes progress {
  from {
    width: 5%;
  }
  to {
    width: 100%;
  }
}
