.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 100%;
  &:hover,
  &:focus {
    .bottom,
    .right,
    .left,
    .top {
      visibility: visible;
      opacity: 1;
    }
  }
  .bottom,
  .right,
  .left,
  .top {
    min-width: 200px;
    padding: 10px 20px;
    color: #444444;
    background-color: #eeeeee;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
  }
  .bottom {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    i {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 12px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #eeeeee;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .right {
    top: 50%;
    left: 100%;
    margin-left: 20px;
    transform: translate(0, -50%);
    i {
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -12px;
      width: 12px;
      height: 24px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 50%;
        transform: translate(50%, -50%) rotate(-45deg);
        background-color: #eeeeee;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .top {
    top: -20px;
    left: 50%;
    transform: translate(-50%, -100%);
    i {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 12px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: #eeeeee;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .left {
    top: 50%;
    right: 100%;
    margin-right: 20px;
    transform: translate(0, -50%);
    i {
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -12px;
      width: 12px;
      height: 24px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        background-color: #eeeeee;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
