@font-face {
  font-family: CustomAnkoBold;
  src: url("../../../assets/fonts/Anko/Anko-PersonalUse-Bold.otf");
}
@font-face {
  font-family: CustomAnkoBoldItalic;
  src: url("../../../assets/fonts/Anko/Anko-PersonalUse-BoldItalic.otf");
}
@font-face {
  font-family: CustomAnkoMedium;
  src: url("../../../assets/fonts/Anko/Anko-PersonalUse-Medium.otf");
}
@font-face {
  font-family: CustomAnkoMediumItalic;
  src: url("../../../assets/fonts/Anko/Anko-PersonalUse-MediumItalic.otf");
}
@font-face {
  font-family: CustomAnkoSemiBold;
  src: url("../../../assets/fonts/Anko/Anko-PersonalUse-SemiBold.otf");
}
@font-face {
  font-family: CustomAnko;
  src: url("../../../assets/fonts/Anko/AnkoPersonalUse.otf");
}
@font-face {
  font-family: CustomAnkoItalic;
  src: url("../../../assets/fonts/Anko/AnkoPersonalUse-RegularItalic.otf");
}
