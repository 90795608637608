:root {
  h1,
  h2,
  h3,
  h5 {
    font-family: CustomNoirProBold, serif;
    font-weight: 700;
  }
  h4,
  h6 {
    font-family: CustomNoirProBold, serif;
  }

  h1 {
    font-size: 24px;
    line-height: 36px;
    &.alt {
      font-size: 28px;
      font-family: CustomAnkoBoldItalic, serif;
    }
  }
  h2 {
    font-size: 20px;
    line-height: 28px;
  }
  h3,
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  h4,
  h6 {
    font-family: CustomNoirProMedium, serif;
  }
  h5,
  h6 {
    font-size: 16px;
    line-height: 22px;
  }
  p {
    color: var(--color-greyscale-6);
    font-family: CustomNoirProRegular, serif;
    font-size: 14px;
    line-height: 20px;
    &.medium {
      font-family: CustomNoirProMedium, serif;
    }
    &.small {
      font-size: 13px;
      line-height: 18px;
    }
  }
  a {
    color: var(--color-greyscale-6);
    font-family: CustomNoirProRegular, serif;
    font-size: 14px;
    line-height: 20px;
  }
  .demoPageSubSection {
    color: var(--color-greyscale-4);
  }
}
