.download-page {
  padding: 30px var(--space-page) 0 var(--space-page);
  flex: 1;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;

  .content-wrapper {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    &.initial-card {
      justify-content: space-between;
    }

    .title {
      width: 80%;
    }
  }

  .app-preview {
    width: 800px;
    transform: translateY(var(--space-md));
  }

  .image-placeholder {
    margin-top: var(--space-md);
    width: 500px;
    flex: 1;
    background-color: #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      opacity: 0.5;
    }
  }
  .phone-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--space-sm);
    width: 100%;
    margin: 0 auto;

    .phone-input {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid var(--color-light-outline);
      background-color: transparent;
      border-radius: 5px;
      padding-left: var(--space-sm);
      min-width: 300px;

      .PhoneInputInput {
        margin-left: var(--space-xs);
        border: none;
        width: 100%;
        height: 100%;
        background-color: transparent;
        padding: var(--space-xs) var(--space-sm);
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:focus {
          outline: none;
        }
      }

      .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border,
      .PhoneInputCountrySelect:focus
        + .PhoneInputCountryIcon
        + .PhoneInputCountrySelectArrow {
        box-shadow: none;
      }

      .PhoneInputCountryIcon--border {
        outline: none;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
