@font-face {
  font-family: CustomMontserrat;
  src: url("../../../assets/fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: CustomMontserratBlack;
  src: url("../../../assets/fonts/Montserrat/Montserrat-Black.ttf");
}
@font-face {
  font-family: CustomMontserratBlackItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf");
}
@font-face {
  font-family: CustomMontserratBold;
  src: url("../../../assets/fonts/Montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: CustomMontserratBoldItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf");
}
@font-face {
  font-family: CustomMontserratExtraBold;
  src: url("../../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: CustomMontserratExtraBoldItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: CustomMontserratExtraLight;
  src: url("../../../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: CustomMontserratExtraLightItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf");
}
@font-face {
  font-family: CustomMontserratItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-Italic.ttf");
}
@font-face {
  font-family: CustomMontserratLightItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-LightItalic.ttf");
}
@font-face {
  font-family: CustomMontserratMedium;
  src: url("../../../assets/fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: CustomMontserratMediumItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf");
}
@font-face {
  font-family: CustomMontserratSemiBold;
  src: url("../../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: CustomMontserratSemiBoldItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf");
}
@font-face {
  font-family: CustomMontserratThin;
  src: url("../../../assets/fonts/Montserrat/Montserrat-Thin.ttf");
}
@font-face {
  font-family: CustomMontserratThinItalic;
  src: url("../../../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf");
}
