.legal-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: var(--space-lg) var(--space-sm);
  @media screen and (min-width: 800px) {
    padding: var(--space-lg) var(--space-page);
  }

  ul,
  ol {
    padding-top: 0.5rem;
    margin-left: 3rem;
  }

  li {
    margin-bottom: 1rem;
  }
}
