.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-lg) var(--space-sm);
  @media screen and (min-width: 800px) {
    padding: var(--space-lg) var(--space-page);
  }

  .about-page-content {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
  }

  .store-links {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
    @media (min-width: 800px) {
      flex-direction: row;
      justify-content: start;
    }
  }
}
