.btn {
  color: white;
  border: none;
  min-width: 100px;
  padding: var(--space-xs) var(--space-sm);
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    filter: brightness(0.8);
  }

  background-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
  &:hover {
    filter: brightness(0.9);
  }

  &.btn-outline {
    color: black;
    background-color: white;
  }

  &.btn-text {
    color: var(--color-primary);
    background-color: transparent;
    outline: none;

    &:hover {
      background-color: transparent;
      opacity: 0.8;
    }
  }
}
