.staging-indicator {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: var(--space-xs) var(--space-sm);
  color: white;
  background-color: #b52a2a;
  border-top-left-radius: 4px;
  font-weight: bold;
}
