.button {
  height: 48px;
  width: 100%;
  background-color: var(--color-accent-4);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary-6);
  font-family: CustomNoirProBold, serif;
  padding: 0 20px;
  transition: all ease 0.2s;
  &:hover {
    &:not(.disabled) {
      cursor: pointer;
      color: var(--color-greyscale-1);
      background-color: var(--color-accent-6);
    }
  }
  &.secondary {
    background-color: var(--color-primary-4);
    color: var(--color-greyscale-1);
    &:hover {
      background-color: var(--color-primary-5);
    }
  }
  &.text {
    background-color: transparent;
    &:hover {
      background-color: initial;
      color: initial;
    }
  }
  &.disabled {
    color: var(--color-greyscale-3);
    background-color: var(--color-greyscale-2);
  }
}
