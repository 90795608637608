.async-button-wrapper {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .waiting-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .waiting-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--color-primary);
      animation: moveDot 1.5s ease 0.15s infinite;

      &:first-child {
        animation-delay: 0s;
      }

      &:last-child {
        animation-delay: 0.3s;
      }
    }
  }
}

@keyframes moveDot {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
}
