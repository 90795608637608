.nav-bar {
  --navbar-height: 3rem;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid transparent;
  transition: border-color 300ms;
  &[data-open="true"] {
    border-color: var(--color-grayscale-2);
    .nav-toggle {
      div:first-child {
        transform: rotate(-45deg) translate3d(-0.475rem, 0.475rem, 0);
      }
      div:nth-child(2) {
        transform: scale(0);
      }
      div:last-child {
        transform: rotate(45deg) translate3d(-0.475rem, -0.475rem, 0);
      }
    }
    .nav-links {
      max-height: 20rem;
    }
  }
  .nav-links {
    overflow: hidden;
    max-height: 0rem;
    transition: max-height 300ms ease-out;
  }
  .nav {
    margin: 2rem 0;
    > * {
      display: block;
      text-decoration: none;
      font-size: 1.2rem;
      line-height: 1.8;
      padding: 0.5rem;
      text-align: center;
    }
    a {
      color: white;
    }
  }
}
.nav-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-toggle {
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem;
  width: var(--navbar-height);
  height: var(--navbar-height);
  div {
    width: 1.5rem;
    height: 2px;
    background: var(--color-primary-1);
    transition: 150ms ease-out;
  }
}
.nav-logo-link {
  display: block;

  width: 10rem;
  height: var(--navbar-height);
}
.get-started-button {
  width: auto;
  margin: 0 0 1rem 0;
}
@media only screen and (min-width: 50rem) {
  .nav-bar {
    flex-direction: row;

    .nav-links {
      width: 100%;
      display: flex;

      max-height: var(--navbar-height);
    }
    .nav {
      justify-content: flex-end;
      flex-grow: 1;
      margin: 0 2rem 0 0;
      display: flex;
      gap: 1rem;
      > *:hover {
        text-decoration: underline;
      }
    }
  }
  .nav-toggle {
    display: none;
  }
  .get-started-button {
    width: auto;
    margin: 0;
  }
}
