.multi-step-option {
  width: 250px;
  border: 1px solid var(--color-primary-3);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  text-transform: capitalize;
  user-select: none;
  &:hover:not(.disabled) {
    cursor: pointer;
    color: white;
    background-color: var(--color-primary-3);
  }
  &.selected {
    background-color: var(--color-primary-3);
    color: white;
    &:hover {
      background-color: var(--color-primary-4);
    }
  }
  &.disabled {
    border-color: var(--color-greyscale-3);
    background-color: var(--color-greyscale-2);
  }
}
