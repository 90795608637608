.progress-wrapper {
  position: relative;
  width: 6rem;
  height: 6rem;
  @media only screen and (min-width: 50rem) {
    width: 12rem;
    height: 12rem;
  }
  #halfCircleProgressBar {
    path {
      stroke-linecap: round;
      will-change: auto;
      stroke-width: 15px;
      stroke-miterlimit: 50;
      transition: stroke-dashoffset 850ms ease-in-out;
    }
  }
  .value-percent {
    font-family: CustomAnkoItalic, serif;
    position: absolute;
    bottom: 1.5rem;
    width: 100%;
    text-align: center;
    font-style: italic;
    @media only screen and (min-width: 50rem) {
      bottom: 4rem;
    }
  }
  .progress-title {
    font-family: CustomNoirProBold, serif;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    @media only screen and (min-width: 50rem) {
      bottom: 3rem;
    }
  }
}
