.text-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .text-input {
    border: 1px solid var(--color-light-outline);
    min-width: 300px;
    border-radius: 5px;
    padding: var(--space-xs) var(--space-sm);
  }
}
