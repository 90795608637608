:root {
  --color-primary-1: #e6f4ff;
  --color-primary-2: #93c7d4;
  --color-primary-3: #277b9d;
  --color-primary-4: #08516f;
  --color-primary-5: #012533;
  --color-primary-6: #151516;

  --color-accent-1: #daf4f6;
  --color-accent-2: #aee8eb;
  --color-accent-3: #82dbe0;
  --color-accent-4: #e5d52d;
  --color-accent-5: #007b83;
  --color-accent-6: #232324;

  --color-error-1: #ffd7d6;
  --color-error-2: #ffa9a6;
  --color-error-3: #ff7a75;
  --color-error-4: #ff4b45;
  --color-error-5: #be3833;
  --color-error-6: #7d2522;

  --color-success-1: #dbf1e3;
  --color-success-12: #b0e0c1;
  --color-success-13: #85d0a0;
  --color-success-14: #5abf7e;
  --color-success-15: #438e5e;
  --color-success-16: #2c5e3e;

  --color-warning-1: #fcf9e3;
  --color-warning-2: #f1e891;
  --color-warning-3: #ebdd60;
  --color-warning-4: #e5d32e;
  --color-warning-5: #ac9e23;
  --color-warning-6: #736a17;

  --color-grayscale-1: #f6f6f6;
  --color-grayscale-2: #c9c9c9;
  --color-grayscale-3: #9c9c9c;
  --color-grayscale-4: #6f6f70;
  --color-grayscale-5: #424243;
  --color-grayscale-6: #151516;
}
.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .page {
    flex-grow: 1;
  }
}
html,
body {
  font-family: CustomNoirProRegular, "Segoe UI", Tahoma, Geneva, Verdana,
    sans-serif;
}
