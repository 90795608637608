.slider-container {
  text-align: left;
  position: relative;
  margin-top: 10px;
  user-select: none;
  width: 100%;
}
.slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 10em;
  height: 1em;
  cursor: pointer;
  margin-top: 0 !important;
  position: absolute;
  top: 1em;
  transform: translateY(-25%);

  &-marker {
    position: absolute;
    top: -1em;
    transform: translateX(-50%);
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
  }

  &.slider::-webkit-slider-thumb {
    background: var(--color-primary);
    outline: 1px solid white;
  }
  color: white;
  background: var(--color-primary);
  background: linear-gradient(
    90deg,
    rgba(230, 244, 255, 1) 0%,
    rgba(147, 199, 212, 1) 20%,
    rgba(39, 123, 157, 1) 40%,
    rgba(8, 81, 111, 1) 60%,
    rgba(1, 37, 51, 1) 80%,
    rgba(21, 21, 22, 1) 100%
  );
}

.slider-wrapper {
  width: 100%;
}
.slider-step-wrapper,
.slider-number-wrapper {
  display: grid;
  height: 2em;
  grid-template-columns: repeat(5, 1fr);
  .slider-number {
    height: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  .slider-step {
    height: 100%;
    display: flex;
    justify-content: space-between;
    &:first-child {
      justify-content: end;
    }
    &:not(&:last-child) {
      border-right: 1px inset rgba(0, 0, 0, 20%);
    }
  }
}
