.slider-answers {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;

  .answer-wrapper {
    width: 100%;
    padding: var(--space-xs) var(--space-sm);
    display: grid;
    grid-template-columns: 1rem 1fr 1.5rem;
    gap: 0.5rem;
    align-items: center;
    border-radius: 10px;
    min-height: 3rem;
    cursor: pointer;

    &:hover {
      background-color: var(--color-greyscale-1);
    }

    .colored-circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    &.selected {
      background-color: rgba(8, 81, 111, 0.25);
    }

    .answer-content {
      display: flex;
      width: 100%;
      gap: 5px;
      align-items: center;

      .selected-icon {
        color: var(--color-primary);
        grid-area: icon;
      }

      .answer-text {
        grid-area: text;
        font-size: 0.9rem;
      }
    }
  }
}
